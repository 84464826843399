import React, { useState } from "react";
import * as styles from "./timetable.module.scss";

function Timetable({ dates, onUpdate }) {
  const [hiddenDates, setHiddenDates] = useState([]);
  let visibleDates;
  if (dates.length > 2) {
    visibleDates = dates.slice(0, 1);
  } else {
    visibleDates = dates;
  }

  function showHiddenDates() {
    if (hiddenDates.length > 0) {
      setHiddenDates([]);
    } else {
      setHiddenDates(dates.slice(1));
    }
    onUpdate();
  }

  return (
    <div className={styles.timetable}>
      <Table dates={visibleDates}></Table>
      {dates.length > 2 && (
        <div className={styles.row}>
          <button onClick={showHiddenDates} className={styles.allDatesButton}>
            {hiddenDates.length > 0 && (
              <>
                <span>Verberg datums</span> <ChevronUpIcon />
              </>
            )}
            {hiddenDates.length == 0 && (
              <>
                <span>Toon alle datums</span> <ChevronDownIcon />
              </>
            )}
          </button>
        </div>
      )}
      {hiddenDates.length > 1 && <Table dates={hiddenDates}></Table>}
    </div>
  );
}

function Table({ dates }) {
  return dates.map((date, index) => {
    return (
      <div key={index} className={styles.row}>
        <div className={styles.date}>
          {date.day_str} {date.day} {date.month_str} {date.year}
        </div>
        <div className={styles.time}>
          <TimeIcon />
          {date.start_hour}:{date.start_minutes} - {date.end_hour}:{date.end_minutes}
        </div>
        {date.remark && (
          <div className={styles.remark}>
            <InfoIcon />
            {date.remark}
          </div>
        )}
      </div>
    );
  });
}

function TimeIcon() {
  return (
    <svg
      height="512"
      width="512"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.icon}
    >
      <path
        style={{
          fill: "none",
          strokeMiterlimit: "10",
          strokeWidth: "32px",
        }}
        d="M256,64C150,64,64,150,64,256s86,192,192,192,192-86,192-192S362,64,256,64Z"
      />
      <polyline
        style={{
          fill: "none",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "32px",
        }}
        points="256 128 256 272 352 272"
      />
    </svg>
  );
}

function InfoIcon() {
  return (
    <svg
      height="512"
      width="512"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.icon}
    >
      <path
        style={{
          fill: "none",
          strokeMiterlimit: "10",
          strokeWidth: "32px",
        }}
        d="M248,64C146.39,64,64,146.39,64,248s82.39,184,184,184,184-82.39,184-184S349.61,64,248,64Z"
      />
      <path d="M248,130a26,26,0,1,0,26,26A26,26,0,0,0,248,130Z" />
      <polyline
        style={{
          fill: "none",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "32px",
        }}
        points="220 220 252 220 252 336"
      />
      <line
        style={{
          fill: "none",
          strokeLinecap: "round",
          strokeMiterlimit: "10",
          strokeWidth: "32px",
        }}
        x1="208"
        x2="296"
        y1="340"
        y2="340"
      />
    </svg>
  );
}

function ChevronDownIcon() {
  return (
    <svg
      height="512"
      width="512"
      className={styles.icon}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline
        style={{
          fill: "none",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "48px",
        }}
        points="112 184 256 328 400 184"
      />
    </svg>
  );
}

function ChevronUpIcon() {
  return (
    <svg
      height="512"
      width="512"
      className={styles.icon}
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <polyline
        style={{
          fill: "none",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeWidth: "48px",
        }}
        points="112 328 256 184 400 328"
      />
    </svg>
  );
}

export default Timetable;
