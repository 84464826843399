import ScheduleCard from "components/schedule-card/schedule-card";
import React, { useReducer, useRef, useEffect } from "react";
import Slider from "components/slider/slider";
import * as styles from "./schedule.module.scss";
import Container from "components/container/container";
import ContentColumn from "components/content-column/content-column";
import Section from "components/section/section";
import SectionTitle from "components/section-title/section-title";

function Schedule({ items: nodes }) {
  // without forcing an update, the slider height isn't updated when the timetable changes.
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const currentDate = new Date();
  const chunkSize = 4;

  let dates = [];
  nodes.forEach((node) => {
    node.data.body1?.forEach((dateSlice) => {
      switch (dateSlice.slice_type) {
        case "enkele_sessie": {
          dates.push({
            url: node.url,
            title: node.data.title.text,
            description: node.data.page_description,
            category: node.data.category.slug,
            dates: [dateSlice.primary],
          });
          break;
        }
        case "meerdere_sessies":
          dates.push({
            url: node.url,
            title: node.data.title.text,
            description: node.data.page_description,
            category: node.data.category.slug,
            dates: dateSlice.items.sort((a, b) => new Date(a.date) - new Date(b.date)),
          });
          break;
        default:
          break;
      }
    });
  });

  // Sort dates chronologically.
  dates.sort((a, b) => new Date(a.dates[0].date) - new Date(b.dates[0].date));

  // Get index of first date to come
  let firstIndex = dates.indexOf(dates.find((x) => new Date(x.dates[0].date) >= currentDate));
  if (firstIndex < 0) firstIndex = 0;

  // Calculate first chunk size
  const firstChunkSize = firstIndex % chunkSize || chunkSize;

  // Split dates into chunks for paging.
  const dateChunks = chunk(dates, firstChunkSize);

  function chunk(arr, firstChunkSize, out) {
    out = out || [];
    if (!arr.length) return out;
    out.push(arr.slice(0, firstChunkSize));
    return chunk(arr.slice(firstChunkSize), chunkSize, out);
  }

  // Calculate index for initialSlide.
  let initialSlide;
  for (let i = 0; i < dateChunks.length; i++) {
    initialSlide = i;
    const firstDate = new Date(dateChunks[i][0].dates[0].date);
    if (firstDate >= currentDate) {
      break;
    }
  }

  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef && sliderRef.current) {
      sliderRef.current.slickGoTo(initialSlide, true);
    }
  }, []);

  const sliderSettings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: false,
    adaptiveHeight: true,
    responsive: null,
    nextArrow: <Arrow direction="next" />,
    prevArrow: <Arrow direction="prev" />,
  };

  if (dateChunks.length == 0) return "";

  return (
    <>
      <Section bg="green" borderColor="blue">
        <SectionTitle>Agenda</SectionTitle>
        <Slider settings={sliderSettings} forwardedRef={sliderRef}>
          {dateChunks.map((dates, index) => {
            const firstDate = dates[0].dates[0];
            const lastDate = dates[dates.length - 1].dates[0];
            let groupTitle = `${firstDate.month_str} ${firstDate.year}`;
            if (firstDate.year !== lastDate.year || firstDate.month !== lastDate.month) {
              groupTitle += ` - ${lastDate.month_str} ${lastDate.year}`;
            }

            return (
              <Container key={index}>
                <ContentColumn>
                  <h3 className={styles.groupTitle}>{groupTitle}</h3>
                  <ul>
                    {dates.map((date, index) => {
                      return (
                        <li className={styles.card} key={index}>
                          <ScheduleCard item={date} onUpdate={forceUpdate}></ScheduleCard>
                        </li>
                      );
                    })}
                  </ul>
                </ContentColumn>
              </Container>
            );
          })}
        </Slider>
      </Section>
    </>
  );
}

function Arrow({ className, onClick, direction }) {
  let arrowClass = "";
  if (direction == "next") {
    arrowClass = styles.nextArrow;
  }
  if (direction === "prev") {
    arrowClass = styles.prevArrow;
  }

  return (
    <div className={styles.arrowContainer}>
      <Container>
        <ContentColumn>
          <div className={`${styles.arrowInner} ${arrowClass}`}>
            <div className={`${className} ${styles.arrow}`} onClick={onClick}></div>
          </div>
        </ContentColumn>
      </Container>
    </div>
  );
}

export default Schedule;
