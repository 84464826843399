import Badge from "components/badge/badge";
import Timetable from "components/timetable/timetable";
import { Link } from "gatsby";
import React from "react";
import * as styles from "./schedule-card.module.scss";

function ScheduleCard({ item, onUpdate }) {
  const firstDate = item.dates[0];
  const lastDate = item.dates.length > 1 && item.dates[item.dates.length - 1];

  return (
    <div className={`card ${styles.card}`}>
      <div className={styles.dates}>
        <div className={styles.date}>
          <span className={styles.day}>{firstDate.day}</span>
          <span>
            {firstDate.month_str} {firstDate.day_str}
          </span>
        </div>

        {lastDate && (
          <>
            <div className={styles.divider}>-</div>
            <div className={`${styles.date} ${styles.small}`}>
              <span className={styles.day}>{lastDate.day}</span>
              <span>{lastDate.month_str}</span>
            </div>
          </>
        )}
      </div>

      <div className={styles.body}>
        <Link to={item.url} className={styles.cardTitle}>
          <h3>{item.title}</h3>
        </Link>
        <div className={styles.badges}>
          <Badge>{item.category}</Badge>
          {lastDate && <Badge>{item.dates.length}-delig</Badge>}
        </div>

        <Timetable dates={item.dates} onUpdate={onUpdate}></Timetable>
      </div>
    </div>
  );
}

export default ScheduleCard;
