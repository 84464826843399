import React from "react";
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick.scss";
import "./slick-theme.scss";

function Slider({ settings, children, forwardedRef }) {
  const defaultSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
          adaptiveHeight: false,
        },
      },
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          adaptiveHeight: true,
        },
      },
    ],
  };
  const sliderSettings = { ...defaultSettings, ...settings };

  return (
    <SlickSlider ref={forwardedRef} {...sliderSettings}>
      {children}
    </SlickSlider>
  );
}

export default Slider;
